@use "../utils/variables" as v;

.base {
  display: flex;
  flex-direction: column;

  &__title button {
    padding-bottom: 0.5%;
    height: 57px;
    /* No events*/
    pointer-events: none;

    @media only screen and (min-width: 390px) {
      height: 45px;
    }
  }
}
