@use "./../../utils/variables" as v;

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  padding: 20px;
  margin: auto;
  margin-top: 7%;
  max-width: 400px;
  background-color: v.$panel-primary-color;
  box-shadow: v.$panel-box-shadow;
  border-radius: v.$panel-border-radius;
}
