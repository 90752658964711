@use "../../utils/variables" as v;

.root {
  text-align: center;
  padding-top: 1%;

  &__button {
    width: auto;
    margin: 4% 7% 1% 7%;
    padding-top: 25px;

    @media only screen and (min-width: v.$mq-sm) {
      width: 30%;
      margin: 1% 35% 1% 35%;
    }
  }
}

.text {
  text-align: justify;
  text-justify: inter-word;
}

table {
  border: 1px solid;
  --yoti-data-table-columns: 1fr 1fr auto;

  @media only screen and (min-width: v.$mq-sm) {
    --yoti-data-table-columns: auto auto auto;
  }
}

textarea:focus-visible {
  outline-color: var(--yoti-grey-lighter);
}
