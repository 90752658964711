/* Import here all the needed .css for the used @yoti/ui-kit-components-react.*/
@import "@yoti/ui-kit-components-react/globals/globals.css";
@import "@yoti/ui-kit-components-react/button/button.css";
@import "@yoti/ui-kit-components-react/tabs/tabs.css";
@import "@yoti/ui-kit-components-react/radio-buttons/radio-buttons.css";
@import "@yoti/ui-kit-components-react/icon/icon.css";
@import "@yoti/ui-kit-components-react/loading/loading.css";
@import "@yoti/ui-kit-components-react/alert/alert.css";
@import "@yoti/ui-kit-components-react/checkbox/checkbox.css";
@import "@yoti/ui-kit-components-react/text-field/text-field.css";
@import "@yoti/ui-kit-components-react/group-fields/group-fields.css";
@import "@yoti/ui-kit-components-react/field-addon/field-addon.css";
@import "@yoti/ui-kit-components-react/stepper/stepper.css";
@import "@yoti/ui-kit-components-react/notification/notification.css";
@import "@yoti/ui-kit-components-react/select-field/select-field.css";
@import "@yoti/ui-kit-components-react/dropdown-menu/dropdown-menu.css";
@import "@yoti/ui-kit-components-react/switch-button/switch-button.css";
@import "@yoti/ui-kit-components-react/text-area/text-area.css";
@import "@yoti/ui-kit-components-react/data-table/data-table.css";

html {
  background: radial-gradient(
      77.59% 77.59% at 50% 22.41%,
      var(--yoti-white) 0%,
      var(--yoti-grey) 100%
    )
    no-repeat center fixed;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
