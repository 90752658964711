$text-primary-color: var(--yoti-grey-black);
$text-secondary-color: var(--yoti-grey-lightest);

$panel-primary-color: var(--yoti-grey-lightest);
$panel-secondary-color: var(--yoti-blue);

$mq-sm: 576px;
$mq-md: 768px;
$mq-lg: 992px;

$panel-box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.25);
$panel-border-radius: 20px;
$panel-opacity: 0.95;

$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-x-large: 24px;

$panel-header-height: 50px;
