@use "../utils/variables" as v;

.alert {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 1%;
  padding: 10px;
  margin: auto;
  --yoti-alert-width: 600px;
}
