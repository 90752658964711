@use "../utils/variables" as v;

.root {
  text-align: center;
  padding-top: 1%;

  &__button {
    width: auto;
    margin: 4% 7% 1% 7%;

    @media only screen and (min-width: v.$mq-sm) {
      width: 30%;
      margin: 1% 35% 1% 35%;
    }
  }

  &__terms {
    text-align: justify;
    text-justify: inter-word;
  }
}

.data_type_text {
  padding-top: 20px;
}

.switch_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-gap: 16px;
  align-items: center;
}
