@use "../utils/variables" as v;

.footer {
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: 60px;

  @media only screen and (min-width: v.$mq-md) {
    margin-top: 30px;
    height: 90px;
  }
}
