@use "../utils/variables" as v;

.displaydata {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1% 25px 25px 25px;
  margin-right: 7%;
  margin-left: 7%;
  background-color: v.$panel-primary-color;
  box-shadow: v.$panel-box-shadow;
  border-radius: v.$panel-border-radius;

  &__data {
    margin: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
  }
}
